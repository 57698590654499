/* Hide scrollbar for Chrome, Safari, and Opera */
.App::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.App {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;     /* Firefox */
}

/* Ensure that scrolling is still possible */
.App {
    height: 100vh;
    overflow-y: scroll;  /* Keep vertical scrolling enabled */
}
